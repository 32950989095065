import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_biomed_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';

const BiomedUnit1 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 1',
    linkLabel: 'BIOMED COURSE',
    to: '/classroom-instruction/biomed',
  };

  const {
    bmCrowdsourcingInnBiotechIntro,
    bmCellBiologyBiomarkers,
    bmMitosisMeiosis,
    bmErrorsCellReplication,
    bmCellDamage,
    bmCulturingCells,
    bmInfectiousDiseases,
    bmChronicDiseases,
    bmDiagnosingDiabetes,
    bmDataGenHealthyDiseasedPatients,
    bmStatSignificantBiomarkers,
    bmWearableDevicesDataCollection,
    bmMockWearableTechInnovation,
  } = getKeyedResources(data);

  return (
    <Layout title="Biomed Unit 1">
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-biomed-unit1@2x.jpg"
        guidSrc="7135495c-ccea-4ffc-a277-4bdaabdc434c"
        posterSrc={'unit-1-biomed-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'orange'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">Crowdsourcing Innovations in Biotech</h1>
        <p className="pb-2">
          How can data help us prevent and control diseases?
        </p>
      </Hero>
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>BIOMED | UNIT 1</div>
            <Link
              to="/classroom-instruction/biomed/unit-2"
              className="course__unit-indicator-link"
            >
              UNIT 2 <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  As our understanding of science is evolving, so is the
                  technology that helps us solve problems.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>¼ School Year</li>
                <li>45 Days</li>
                <li>4 Flex Days Built In</li>
                <li>45-minute Classes</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Guiding Question</li>
                <li>Engineering Design Process</li>
                <li>Project Based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>10 Lessons with Lab Elements</li>
                <li>1 Core Lab</li>
                <li>1 Final Project</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, student capture sheets
                and presentations that empower educators to engage all students.
                These lesson bundles can be downloaded, for the use in the
                classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-BM-U1.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 15 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        <Container fullWidth>
          <Row>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCrowdsourcingInnBiotechIntro.tophat}
                tophatClass={`${bmCrowdsourcingInnBiotechIntro.page}__resource-tophat`}
                ribbon={bmCrowdsourcingInnBiotechIntro.ribbon}
                ribbonClass={`${bmCrowdsourcingInnBiotechIntro.page}__resource-ribbon`}
                img=""
                duration={bmCrowdsourcingInnBiotechIntro.duration}
                subject={bmCrowdsourcingInnBiotechIntro.subject}
                subjectClass={`${bmCrowdsourcingInnBiotechIntro.page}__resource-subject`}
                title={bmCrowdsourcingInnBiotechIntro.title}
                description={bmCrowdsourcingInnBiotechIntro.description}
                actions={bmCrowdsourcingInnBiotechIntro.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCellBiologyBiomarkers.tophat}
                tophatClass={`${bmCellBiologyBiomarkers.page}__resource-tophat`}
                ribbon={bmCellBiologyBiomarkers.ribbon}
                ribbonClass={`${bmCellBiologyBiomarkers.page}__resource-ribbon`}
                img=""
                duration={bmCellBiologyBiomarkers.duration}
                subject={bmCellBiologyBiomarkers.subject}
                subjectClass={`${bmCellBiologyBiomarkers.page}__resource-subject`}
                title={bmCellBiologyBiomarkers.title}
                description={bmCellBiologyBiomarkers.description}
                actions={bmCellBiologyBiomarkers.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmMitosisMeiosis.tophat}
                tophatClass={`${bmMitosisMeiosis.page}__resource-tophat`}
                ribbon={bmMitosisMeiosis.ribbon}
                ribbonClass={`${bmMitosisMeiosis.page}__resource-ribbon`}
                img=""
                duration={bmMitosisMeiosis.duration}
                subject={bmMitosisMeiosis.subject}
                subjectClass={`${bmMitosisMeiosis.page}__resource-subject`}
                title={bmMitosisMeiosis.title}
                description={bmMitosisMeiosis.description}
                actions={bmMitosisMeiosis.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmErrorsCellReplication.tophat}
                tophatClass={`${bmErrorsCellReplication.page}__resource-tophat`}
                ribbon={bmErrorsCellReplication.ribbon}
                ribbonClass={`${bmErrorsCellReplication.page}__resource-ribbon`}
                img=""
                duration={bmErrorsCellReplication.duration}
                subject={bmErrorsCellReplication.subject}
                subjectClass={`${bmErrorsCellReplication.page}__resource-subject`}
                title={bmErrorsCellReplication.title}
                description={bmErrorsCellReplication.description}
                actions={bmErrorsCellReplication.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCellDamage.tophat}
                tophatClass={`${bmCellDamage.page}__resource-tophat`}
                ribbon={bmCellDamage.ribbon}
                ribbonClass={`${bmCellDamage.page}__resource-ribbon`}
                img=""
                duration={bmCellDamage.duration}
                subject={bmCellDamage.subject}
                subjectClass={`${bmCellDamage.page}__resource-subject`}
                title={bmCellDamage.title}
                description={bmCellDamage.description}
                actions={bmCellDamage.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCulturingCells.tophat}
                tophatClass={`${bmCulturingCells.page}__resource-tophat`}
                ribbon={bmCulturingCells.ribbon}
                ribbonClass={`${bmCulturingCells.page}__resource-ribbon`}
                img=""
                duration={bmCulturingCells.duration}
                subject={bmCulturingCells.subject}
                subjectClass={`${bmCulturingCells.page}__resource-subject`}
                title={bmCulturingCells.title}
                description={bmCulturingCells.description}
                actions={bmCulturingCells.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmInfectiousDiseases.tophat}
                tophatClass={`${bmInfectiousDiseases.page}__resource-tophat`}
                ribbon={bmInfectiousDiseases.ribbon}
                ribbonClass={`${bmInfectiousDiseases.page}__resource-ribbon`}
                img=""
                duration={bmInfectiousDiseases.duration}
                subject={bmInfectiousDiseases.subject}
                subjectClass={`${bmInfectiousDiseases.page}__resource-subject`}
                title={bmInfectiousDiseases.title}
                description={bmInfectiousDiseases.description}
                actions={bmInfectiousDiseases.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmChronicDiseases.tophat}
                tophatClass={`${bmChronicDiseases.page}__resource-tophat`}
                ribbon={bmChronicDiseases.ribbon}
                ribbonClass={`${bmChronicDiseases.page}__resource-ribbon`}
                img=""
                duration={bmChronicDiseases.duration}
                subject={bmChronicDiseases.subject}
                subjectClass={`${bmChronicDiseases.page}__resource-subject`}
                title={bmChronicDiseases.title}
                description={bmChronicDiseases.description}
                actions={bmChronicDiseases.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmDiagnosingDiabetes.tophat}
                tophatClass={`${bmDiagnosingDiabetes.page}__resource-tophat`}
                ribbon={bmDiagnosingDiabetes.ribbon}
                ribbonClass={`${bmDiagnosingDiabetes.page}__resource-ribbon`}
                img=""
                duration={bmDiagnosingDiabetes.duration}
                subject={bmDiagnosingDiabetes.subject}
                subjectClass={`${bmDiagnosingDiabetes.page}__resource-subject`}
                title={bmDiagnosingDiabetes.title}
                description={bmDiagnosingDiabetes.description}
                actions={bmDiagnosingDiabetes.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmDataGenHealthyDiseasedPatients.tophat}
                tophatClass={`${bmDataGenHealthyDiseasedPatients.page}__resource-tophat`}
                ribbon={bmDataGenHealthyDiseasedPatients.ribbon}
                ribbonClass={`${bmDataGenHealthyDiseasedPatients.page}__resource-ribbon`}
                img=""
                duration={bmDataGenHealthyDiseasedPatients.duration}
                subject={bmDataGenHealthyDiseasedPatients.subject}
                subjectClass={`${bmDataGenHealthyDiseasedPatients.page}__resource-subject`}
                title={bmDataGenHealthyDiseasedPatients.title}
                description={bmDataGenHealthyDiseasedPatients.description}
                actions={bmDataGenHealthyDiseasedPatients.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmStatSignificantBiomarkers.tophat}
                tophatClass={`${bmStatSignificantBiomarkers.page}__resource-tophat`}
                ribbon={bmStatSignificantBiomarkers.ribbon}
                ribbonClass={`${bmStatSignificantBiomarkers.page}__resource-ribbon`}
                img=""
                duration={bmStatSignificantBiomarkers.duration}
                subject={bmStatSignificantBiomarkers.subject}
                subjectClass={`${bmStatSignificantBiomarkers.page}__resource-subject`}
                title={bmStatSignificantBiomarkers.title}
                description={bmStatSignificantBiomarkers.description}
                actions={bmStatSignificantBiomarkers.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmWearableDevicesDataCollection.tophat}
                tophatClass={`${bmWearableDevicesDataCollection.page}__resource-tophat`}
                ribbon={bmWearableDevicesDataCollection.ribbon}
                ribbonClass={`${bmWearableDevicesDataCollection.page}__resource-ribbon`}
                img=""
                duration={bmWearableDevicesDataCollection.duration}
                subject={bmWearableDevicesDataCollection.subject}
                subjectClass={`${bmWearableDevicesDataCollection.page}__resource-subject`}
                title={bmWearableDevicesDataCollection.title}
                description={bmWearableDevicesDataCollection.description}
                actions={bmWearableDevicesDataCollection.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 1',
            link: '/classroom-instruction/biomed/unit-1',
          }}
          next={{
            name: 'Unit 2',
            link: '/classroom-instruction/biomed/unit-2',
          }}
          previous={{
            name: 'Overview',
            link: '/classroom-instruction/biomed',
          }}
        />
      </Section>
    </Layout>
  );
};

export default BiomedUnit1;

export const query = graphql`
  query BiomedUnit1Resources {
    allResourcesJson(filter: { page: { eq: "biomed" }, unit: { eq: 1 } }) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
